import { Injectable, inject } from '@angular/core';
import { AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Client } from '@appbolaget/aware-model';
import { BehaviorSubject, Observable, Subject, map, switchMap, take, tap } from 'rxjs';
import { ContentMapClientPivotType } from './symbols';
import { ECrudPermission } from '@components/form-components/crud-permissions/symbols';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { CrudPermissionsService } from '@components/form-components/crud-permissions/crud-permissions.service';
import { ContentMapItem } from './ContentMapItem';
import { AwareAuthService } from '@appbolaget/aware-auth';

const getColor = (category: ContentMapItem): string => {
    if (category.attr('color')) {
        return category.attr('color');
    }

    return category.parent ? getColor(category.parent) : null;
};

const getTopParent = (category: ContentMapItem): ContentMapItem => {
    if (category.parent && category.parent.type === 'content-map-item') {
        return getTopParent(category.parent);
    }

    return category;
};

@Injectable({ providedIn: 'root' })
export class ContentMapService {
    rootContentMap$: BehaviorSubject<ContentMapItem> = new BehaviorSubject<ContentMapItem>(null);
    _breadcrumbs$: BehaviorSubject<ContentMapItem[]> = new BehaviorSubject<ContentMapItem[]>([]);
    breadcrumbs$ = this._breadcrumbs$.asObservable();
    toggledFavorite$: Subject<ContentMapItem> = new Subject<ContentMapItem>();

    private api = inject(AwareHttpService);
    private ass = inject(AwareSecurityService);
    private auth = inject(AwareAuthService);
    private crudPermissionService = inject(CrudPermissionsService);

    constructor() {
        if (!this.rootContentMap$.value) {
            this._getRootContentMap();
        }
    }

    addBreadcrumb(category: ContentMapItem) {
        this._breadcrumbs$.next([...this._breadcrumbs$.value, category]);
    }

    clearBreadcrumbs() {
        this._breadcrumbs$.next([]);
    }

    popBreadcrumb() {
        this._breadcrumbs$.next(this._breadcrumbs$.value.slice(0, -1));
    }

    setBreadcrumbsFromParent(item: ContentMapItem) {
        const getParents = (category: ContentMapItem): ContentMapItem[] => {
            if (category.parent && category.parent.type === 'content-map-item') {
                return [...getParents(category.parent), category];
            }

            return [category];
        };

        const parents = getParents(item);
        this._breadcrumbs$.next(parents);
    }

    getFavorites(): Observable<IAwareCollection<ContentMapItem>> {
        return this.auth.client$.pipe(
            take(1),
            switchMap((me) =>
                this.api
                    .get<IAwareCollection<ContentMapItem>>('categories')
                    .parameter('pivot', JSON.stringify({ clients: { client_id: me.id, type: ContentMapClientPivotType.Favorite } }))
                    .toCollection(ContentMapItem)
                    .stream('up')
                    .with('attributes,parent^*.attributes')
                    .execute()
                    .pipe(
                        map((collection) => {
                            collection.data.forEach((category) => {
                                category.hasClientPivot = true;
                                category.localProps = {
                                    color: getColor(category),
                                };
                            });

                            collection.data = collection.data.sort((a, b) => {
                                const topParentA = getTopParent(a);
                                const topParentB = getTopParent(b);

                                if (topParentA.uuid === topParentB.uuid) {
                                    return a.title.localeCompare(b.title);
                                }

                                return topParentA.position > topParentB.position ? 1 : -1;
                            });

                            return collection;
                        }),
                    ),
            ),
        );
    }

    toggleFavorite(category: ContentMapItem, client: Client): Observable<boolean> {
        return this.ass.isGranted('category.delete').pipe(
            take(1),
            switchMap((hasDeletePermission) =>
                this.api
                    .put(
                        category.hasClientPivot
                            ? `categories/${category.uuid}/detach/clients`
                            : `categories/${category.uuid}/attach/clients`,
                        [
                            {
                                id: client.uuid,
                                fields: {
                                    type: ContentMapClientPivotType.Favorite,
                                    permissions: hasDeletePermission
                                        ? this.crudPermissionService.getCleanPermissionValue(
                                              ECrudPermission.Read,
                                              ECrudPermission.Create,
                                              ECrudPermission.Update,
                                              ECrudPermission.Delete,
                                          )
                                        : this.crudPermissionService.getCleanPermissionValue(ECrudPermission.Read),
                                },
                            },
                        ],
                    )
                    .stream('*')
                    .execute()
                    .pipe(
                        tap(() => {
                            category.hasClientPivot = !category.hasClientPivot;
                            this.toggledFavorite$.next(category);
                        }),
                        map((_) => true),
                    ),
            ),
        );
    }

    private _getRootContentMap() {
        this.api
            .get<IAwareCollection<ContentMapItem>>('categories')
            .type('content-map')
            .header('hidden', '0')
            .stream('up')
            .toCollection(ContentMapItem)
            .execute()
            .subscribe({
                next: (categories) => {
                    this.rootContentMap$.next(categories.data[0]);
                },
            });
    }
}
