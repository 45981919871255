import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable, Subject, filter, takeUntil, tap } from 'rxjs';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { SpotlightComponent } from '@components/spotlight/spotlight.component';
import { SidenavComponent, TopbarComponent } from '@components/index';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AsyncPipe } from '@angular/common';
import { ContentMapFabComponent } from '@modules/content-map/ui/content-map-fab.component';
import { HasExtensionDirective } from '@appbolaget/aware-extension';
import { AwareAuthIdleService, AwareAuthLogoutReason, AwareAuthService } from '@appbolaget/aware-auth';
import { DialogService } from '@viewservices/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AwareAuthIdleWarningComponent } from '@modules/auth/components/idle-warning/idle-warning.component';

@Component({
    selector: 'pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    imports: [
        SpotlightComponent,
        SidenavComponent,
        MatSidenavModule,
        AsyncPipe,
        TopbarComponent,
        RouterOutlet,
        ContentMapFabComponent,
        HasExtensionDirective,
    ],
    standalone: true,
})
export class Pages implements OnInit, OnDestroy {
    canViewSidenav$: Observable<boolean>;
    destroy$: Subject<void>;

    #awareSecurityService = inject(AwareSecurityService);
    #awareAuthService = inject(AwareAuthService);
    #awareAuthIdleService = inject(AwareAuthIdleService);
    #dialogService = inject(DialogService);

    constructor(public router: Router) {}

    async ngOnInit() {
        this.destroy$ = new Subject();
        this.canViewSidenav$ = this.#awareSecurityService.isGranted('sidemenu.read');

        let awareAuthIdleDialog: MatDialogRef<AwareAuthIdleWarningComponent>;

        this.#awareAuthIdleService.onIdleWarning$
            .pipe(
                takeUntil(this.destroy$),
                filter(() => !awareAuthIdleDialog),
                tap(() => {
                    awareAuthIdleDialog = this.#dialogService.open(AwareAuthIdleWarningComponent, {
                        width: '95%',
                        maxWidth: '500px',
                        disableClose: true,
                    });

                    awareAuthIdleDialog.afterClosed().subscribe(() => {
                        awareAuthIdleDialog = null;
                    });
                }),
            )
            .subscribe();

        this.#awareAuthIdleService.onTimeout$
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.#awareAuthService.logout({
                        reason: AwareAuthLogoutReason.idle,
                        message: 'Du har blivit utloggad på grund av inaktivitet.',
                    });
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onInactive() {
        // this.auth.signout();
        // this.router.navigate(['/login']);
    }
}
