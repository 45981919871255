import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationType } from './symbols';
import { NotificationState } from './notification.state';
import {
    ClearNotificationsByType,
    ClearLocalNotifications,
    GetMyNotifications,
    ClearNotificationsByUnit,
    ClearNotification,
    ClearNotificationsByTypeAndValue,
} from './notification.actions';
import { tap, takeUntil, delay, filter } from 'rxjs/operators';
import { timer } from 'rxjs';
import { Client } from '@appbolaget/aware-model';
import { Config } from '@services/config';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { Notification } from './Notification';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    IS_LISTENING: boolean;

    #document = inject(DOCUMENT);
    private awareAuth = inject(AwareAuthService);
    private document = inject(DOCUMENT);

    constructor(
        private store: Store,
        private config: Config,
    ) {
        this._initListeners();
    }

    clearNotification(notification: Notification) {
        return this.store.dispatch(new ClearNotification(notification));
    }

    clearNotificationByTypeAndValue(type: string, value: string) {
        return this.store.dispatch(new ClearNotificationsByTypeAndValue(type, value));
    }

    checkNotificationsForType(type: NotificationType) {
        const notifications = this.store.selectSnapshot(NotificationState.notificationsByType(type));

        if (notifications && notifications.length) {
            this.store.dispatch(new ClearNotificationsByType(type));
        }
    }

    checkNotificationsForUnit(unit_id: number, unit_uuid: string) {
        const notifications = this.store.selectSnapshot(NotificationState.notificationsByUnit(unit_id));

        if (notifications && notifications.length) {
            this.store.dispatch(new ClearNotificationsByUnit(unit_uuid));
        }
    }

    private _initListeners() {
        this.awareAuth.onLogout$
            .pipe(
                tap((_) => {
                    this.IS_LISTENING = false;
                    this.store.dispatch(new ClearLocalNotifications());
                }),
            )
            .subscribe();

        this.awareAuth.client$
            .pipe(
                delay(3000),
                filter((client) => client instanceof Client && !this.IS_LISTENING),
                tap(() => {
                    this._startNotificationListener();
                }),
            )
            .subscribe();
    }

    private _startNotificationListener() {
        if (this.IS_LISTENING) {
            return;
        }

        if (!this.config.get('unitConfig.panel.notifications.enabled')) {
            return;
        }

        this.IS_LISTENING = true;

        timer(0, 40000)
            .pipe(
                filter(() => this.document.visibilityState === 'visible'),
                takeUntil(this.awareAuth.onLogout$),
                filter(() => this.#document.visibilityState === 'visible'),
                tap((_) => {
                    this.store.dispatch(new GetMyNotifications());
                }),
            )
            .subscribe();
    }
}
