import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '@viewservices/dialog.service';
import { ContentMapDialogComponent } from '../content-map-dialog/content-map-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'content-map-fab',
    template: `
        <button
            mat-fab
            color="primary"
            cdkDrag
            cdkDragBoundary=".Main"
            matTooltip="Öppna utbudskartan"
            class="!fixed bottom-4 right-4 z-50"
            (click)="toggleContentMapDialog($event)"
        >
            <mat-icon>content_copy</mat-icon>
        </button>
    `,
    styles: [
        `
            button.mat-mdc-fab {
                bottom: 60px;
            }
        `,
    ],
    standalone: true,
    imports: [MatIconModule, DragDropModule, MatTooltipModule, MatButtonModule],
})
export class ContentMapFabComponent {
    dialogRef: MatDialogRef<ContentMapDialogComponent>;
    constructor(private dialogService: DialogService) {}

    toggleContentMapDialog(e: MouseEvent) {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }

        this.dialogRef = this.dialogService.open(ContentMapDialogComponent, {
            width: '600px',
            minWidth: '300px',
            maxWidth: '2280px',
            height: '700px',
            maxHeight: '95vh',
            hasBackdrop: false,
            panelClass: ['Dialog', 'Dialog--resizeable', 'Dialog--resizeable--rtl', 'Dialog--content-map'],
            position: {
                right: e.view.innerWidth - e.clientX + 'px',
                top: Math.max(e.clientY - 700, 10) + 'px',
                // right: '20px',
                // bottom: '80px',
                // left: '200px',
                // top: '200px',
                // left: `${e.pageX - 500}px`,
                // top: `${e.pageY - 500}px`,
            },
        });
    }
}
