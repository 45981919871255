import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Category } from '@appbolaget/aware-model';
import { AwareCalendarPost } from '@models/AwareCalendarPost';
import moment from 'moment';
import { map, Observable, shareReplay } from 'rxjs';
import { ActivityCardComponent } from './activity-card/activity-card.component';

@Component({
    selector: 'content-map-activities',
    templateUrl: 'activities.component.html',
    standalone: true,
    imports: [ActivityCardComponent, AsyncPipe],
})
export class ContentMapActivitiesComponent implements OnInit {
    _category: Category;
    @Input() set category(category: Category) {
        this._category = category;

        if (category instanceof Category) {
            this._getActivities(category);
        } else {
            this.activities$ = null;
            this.otherPosts$ = null;
        }
    }

    get category(): Category {
        return this._category;
    }

    activities$: Observable<IAwareCollection<AwareCalendarPost>>;
    otherPosts$: Observable<[AwareCalendarPost[], AwareCalendarPost[]]>;

    constructor(private api: AwareHttpService) {}

    ngOnInit() {}

    _getActivities(category: Category) {
        this.activities$ = this.api
            .get<IAwareCollection<AwareCalendarPost>>('posts')
            .type('activity')
            .parameters({
                categories: category.uuid,
                start_at: moment().format('YYYY-MM-DD HH:mm'),
                end_at: moment().add(3, 'months').format('YYYY-MM-DD HH:mm'),
            })
            .toCollection(AwareCalendarPost)
            .with('categories.attributes,attributes')
            .sort('start_at', 'asc')
            .stream('*')
            .execute()
            .pipe(
                map((collection) => {
                    const seriesCountMap = new Map<string, number>();

                    collection.data = collection.data.filter((post) => {
                        const seriesCount = seriesCountMap.get(post.title);
                        if (seriesCount) {
                            seriesCountMap.set(post.title, seriesCount + 1);
                        } else {
                            seriesCountMap.set(post.title, 1);
                        }
                        return seriesCountMap.get(post.title) <= 3;
                    });
                    return collection;
                }),
            );

        this.otherPosts$ = this.api
            .get<IAwareCollection<AwareCalendarPost>>('posts')
            .type('misc-activity', 'news')
            .parameter('categories', category.uuid)
            .toCollection(AwareCalendarPost)
            .with('categories.attributes,attributes')
            .sort('publish_at', 'desc')
            .stream('*')
            .execute()
            .pipe(
                map((posts) => {
                    return [posts.data.filter((p) => p.type === 'news'), posts.data.filter((p) => p.type === 'misc-activity')] as [
                        AwareCalendarPost[],
                        AwareCalendarPost[],
                    ];
                }),
                shareReplay(1),
            );
    }
}
