import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { Store } from '@ngxs/store';
import { SetActiveUnit, SetActiveUnitIfNotSet } from 'app/state/unit.actions';
import { UnitState } from 'app/state/unit.state';
import { Observable, of, switchMap, take } from 'rxjs';

export const externalUnitResolver: ResolveFn<void> = (): Observable<void> => {
    const store = inject(Store);
    const awareAuth = inject(AwareAuthService);

    return awareAuth.clientFiltered$.pipe(
        switchMap((me) =>
            store.selectOnce(UnitState.activeUnit).pipe(
                switchMap((activeUnit) => {
                    if (me.type === 'external') {
                        return store.dispatch(new SetActiveUnit(null));
                    } else if (!activeUnit) {
                        return store.dispatch(new SetActiveUnitIfNotSet(me.units));
                    }

                    return of(null);
                }),
                take(1),
            ),
        ),
    );
};
