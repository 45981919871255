<div class="Dialog Content-map h-full flex flex-col">
  <mat-toolbar class="Dialog__header Dialog__header--light flex flex-col flex-none">
    <mat-toolbar-row class="order-1" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>
      <h2 mat-dialog-title>{{ data?.title || 'Utbudskarta' }}</h2>
      <span class="flex-1"></span>
      <button
        mat-icon-button
        type="button"
        tabindex="-1"
        (click)="toggleFavoritesView()"
        [matTooltip]="(isFavoriteView$ | async) ? 'Dölj favoriter' : 'Visa favoriter'"
      >
        <mat-icon class="text-black">{{ (isFavoriteView$ | async) === true ? 'favorite' : 'favorite_border' }}</mat-icon>
      </button>
      <button mat-icon-button type="button" tabindex="-1" (click)="dismiss()">
        <mat-icon class="text-black">close</mat-icon>
      </button>
    </mat-toolbar-row>
    @if (!data?.hideSearch && !(isFavoriteView$ | async)) {
      <mat-toolbar-row class="Content-map__searchrow order-2">
        <mat-form-field>
          <mat-label>Sök</mat-label>
          <input matInput type="search" [formControl]="searchControl" autocomplete="off" />
          @if (searchControl.value?.length > 0) {
            <button mat-icon-button type="button" matSuffix (click)="searchControl.setValue('')">
              <mat-icon class="text-gray-600">clear</mat-icon>
            </button>
          }
        </mat-form-field>
      </mat-toolbar-row>
    }
    @if (breadcrumbs$ | async; as breadcrumbs) {
      @if (breadcrumbs.length) {
        <mat-toolbar-row class="order-3 !items-center">
          <div class="flex items-center gap-2 w-full">
            <button class="text-black" mat-icon-button (click)="goHome()">
              <mat-icon>home</mat-icon>
            </button>
            <button class="text-black" mat-icon-button (click)="onBackClick()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            @if (lastBreadcrumb$ | async; as currentPost) {
              <span [style.color]="itemBackgroundColor" class="overflow-hidden text-ellipsis">{{ currentPost.title }}</span>
              <span class="flex-1"></span>
              <button
                class="text-black"
                mat-icon-button
                (click)="toggleFavorite(currentPost)"
                [matTooltip]="currentPost.hasClientPivot ? 'Ta bort från favoriter' : 'Lägg till i favoriter'"
              >
                <mat-icon>{{ currentPost.hasClientPivot ? 'favorite' : 'favorite_border' }}</mat-icon>
              </button>
            }
          </div>
        </mat-toolbar-row>
      }
    }
  </mat-toolbar>

  <mat-progress-bar mode="indeterminate" [hidden]="!IS_LOADING"></mat-progress-bar>
  <div mat-dialog-content class="Dialog__content">
    @if (lastBreadcrumb$ | async; as currentPost) {
      <div [scrollableContent]="currentPost.uuid" [expandable]="true" [maxHeight]="400" [scrollable]="false">
        @if (currentPost.content) {
          <app-content-view class="p-2" [content]="currentPost.content"></app-content-view>
        }
        <!-- <div class="ck-content p-2 leading-6" *ngIf="currentPost.content" [innerHtml]="currentPost.content"></div> -->
      </div>
    }
    @if (categories$ | async; as categories) {
      @if (!IS_LOADING) {
        <div class="grid gap-2 grid-cols-2 p-2">
          @for (category of categories.data; track category) {
            <button
              class="relative h-20 grid__item rounded-md flex items-center justify-center"
              (click)="onItemClick(category)"
              [style.--bgColor]="category.localProps?.color || category.attr('color') || itemBackgroundColor"
            >
              @if (category.attr('type') === 'url') {
                <mat-icon class="absolute top-2 right-2 text-white">open_in_new</mat-icon>
              }
              <span class="text-white font-semibold">{{ category.title }}</span>
            </button>
          }
        </div>
      }
    }

    <content-map-activities [category]="lastBreadcrumb$ | async"></content-map-activities>
  </div>
</div>
