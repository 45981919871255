import { Routes } from '@angular/router';

import { Pages } from './pages.component';
import { godGuard, participantGuard } from '../../helpers/guards';
import { awareAuthGuard } from '@appbolaget/aware-auth';
import { FieldType } from './fields/symbols';
import { notExternalClientGuard } from '@helpers/guards/not-external-client.guard';

export const routes: Routes = [
    {
        path: 'login',
        redirectTo: '/auth/login',
    },
    {
        path: 'login/new-password/:token',
        redirectTo: '/auth/new-password/:token',
    },
    {
        path: 'login/verify-password/:token',
        redirectTo: '/auth/verify-password/:token',
    },
    {
        path: 'login/delete-client/:token',
        redirectTo: '/auth/delete-client/:token',
    },
    {
        path: 'login/forgot-password',
        redirectTo: '/auth/forgot-password',
    },
    {
        path: 'auth',
        loadChildren: () => import('@modules/auth/auth.routing').then((m) => m.routes),
    },
    {
        path: 'app',
        canActivate: [awareAuthGuard, notExternalClientGuard()],
        component: Pages,
        children: [
            { path: '', redirectTo: 'me', pathMatch: 'full' },
            {
                path: 'me',
                loadChildren: () => import('@pages/clients/show/show.routing').then((m) => m.routes),
                data: {
                    isMe: true,
                },
            },
            {
                path: 'posts',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
            },
            {
                path: 'forum',
                loadChildren: () => import('@modules/forum/forum.routing').then((m) => m.routes),
            },
            {
                path: '',
                canActivate: [participantGuard()],
                children: [
                    {
                        path: 'dashboard',
                        loadChildren: () => import('@pages/dashboard/dashboard.routing').then((m) => m.routes),
                        data: { root: true },
                    },
                    {
                        path: 'fields',
                        loadChildren: () => import('@pages/fields/fields.routing').then((m) => m.routes),
                        data: { type: FieldType.Nav },
                    },
                    {
                        path: 'follow-up',
                        loadChildren: () => import('@pages/fields/fields.routing').then((m) => m.routes),
                        data: { type: FieldType.FollowUp },
                    },
                    { path: 'matching', loadChildren: () => import('@pages/matching/matching.routing').then((m) => m.routes) },
                    { path: 'kga', loadChildren: () => import('@modules/kga/kga.routing').then((m) => m.routes) },
                    {
                        path: 'extensions',
                        loadChildren: () => import('@pages/extensions/extensions.routing').then((m) => m.routes),
                    },
                    { path: 'reports', loadChildren: () => import('@pages/report/report.routing').then((m) => m.routes) },
                    {
                        path: 'calendar',
                        loadChildren: () => import('@pages/calendar/calendar.routing').then((m) => m.routes),
                    },
                    {
                        path: 'clients',
                        loadChildren: () => import('@pages/clients/clients.routing').then((m) => m.routes),
                    },
                    {
                        path: 'locations',
                        loadChildren: () => import('@pages/locations/locations.routing').then((m) => m.routes),
                    },
                    {
                        path: 'messages',
                        loadChildren: () => import('@pages/messages/messages.routes').then((m) => m.routes),
                    },
                    {
                        path: 'approutes',
                        loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                        data: { type: 'approute' },
                    },
                    {
                        path: 'pages',
                        loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                        data: { type: 'page' },
                    },
                    {
                        path: 'news',
                        loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                        data: { type: 'news' },
                    },
                    {
                        path: 'navigations',
                        loadChildren: () => import('@pages/navigation/navigation.routing').then((m) => m.routes),
                    },
                    {
                        path: 'units',
                        loadChildren: () => import('@pages/units/units.routing').then((m) => m.routes),
                    },
                    {
                        path: 'olympus',
                        loadChildren: () => import('@pages/olympus/olympus.routing').then((m) => m.routes),
                        canActivate: [godGuard()],
                    },
                    {
                        path: 'notifications',
                        loadChildren: () => import('@pages/notification/notification.routing').then((m) => m.routes),
                    },
                    {
                        path: 'feedback',
                        loadChildren: () => import('@modules/feedback/feedback.routing').then((m) => m.routes),
                    },
                    {
                        path: 'categories',
                        loadChildren: () => import('@pages/categories/categories.routing').then((m) => m.routes),
                    },
                    {
                        path: 'employers',
                        loadChildren: () => import('@modules/employer/employer.routing').then((m) => m.routes),
                    },
                    {
                        path: 'elearning',
                        loadChildren: () => import('@modules/e-learning/e-learning.routing').then((m) => m.routes),
                        data: {
                            admin: true,
                        },
                    },
                ],
            },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
    },
];
