import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AwareAuthService } from '@appbolaget/aware-auth';

export function notExternalClientGuard(): CanActivateFn {
    return () => {
        const awareAuth = inject(AwareAuthService);
        if (awareAuth.client.type !== 'external') {
            return true;
        }

        awareAuth.logout();
        return false;
    };
}
