<main id="main" class="Main">
  <mat-progress-bar
    class="Main__progressbar"
    [mode]="progressbar.mode"
    [class.Main__progressbar--show]="progressbar.show"
  ></mat-progress-bar>
  <div class="hidden-noprint">
    <div class="flex items-center">
      <img
        class="mr-3"
        src="https://api-skills.appbolaget.se/@media/00000000-0000-0000-0000-000000000001:main_logo?w=120"
        style="height: 40px"
      />
      <h3 class="mb-0">Skills Sverige</h3>
    </div>
  </div>
  <router-outlet></router-outlet>
</main>
