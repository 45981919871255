import { enableProdMode } from '@angular/core';
import localeSv from '@angular/common/locales/sv';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { applicationConfig } from 'app/app.config';
import { registerLocaleData } from '@angular/common';
import { bootstrapApplication } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular';
import { awareSentryBeforeSend } from '@appbolaget/sentry';

Sentry.init({
    dsn: environment.sentry.dsn,
    enabled: environment.production,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environment.production ? 0.1 : 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/sentry\.appbolaget\.dev\/api/],
    beforeSend: awareSentryBeforeSend,
});

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, applicationConfig).catch((err) => console.log(err));

registerLocaleData(localeSv);
