import { Category, Expand, Integer } from '@appbolaget/aware-model';

export type ContentMapClientPivot = {
    type: string;
    permissions: string;
};
export class ContentMapItem extends Category {
    static fields = [...Category.fields, 'client-pivots'];

    'client-pivots': ContentMapClientPivot[];

    @Expand(ContentMapItem)
    parent: ContentMapItem;

    @Integer()
    position: number;

    constructor(data?: any, model = ContentMapItem) {
        super(data, model);
    }

    get hasClientPivot(): boolean {
        return this['client-pivots'] && this['client-pivots'].length > 0;
    }

    set hasClientPivot(value: boolean) {
        if (value) {
            this['client-pivots'] = [{ type: 'favorite', permissions: 'crud' }];
        } else {
            this['client-pivots'] = null;
        }
    }
}
