<!-- <mat-sidenav-container idle (onInactive)="onInactive()"> -->
<mat-sidenav-container>
  <mat-sidenav
    #mainSidenav
    class="Sidenav Sidenav--left !rounded-tr-2xl"
    mode="side"
    [opened]="canViewSidenav$ | async"
    [fixedInViewport]="true"
  >
    @if (canViewSidenav$ | async) {
      <aw-sidenav [nav]="mainSidenav"></aw-sidenav>
    }
  </mat-sidenav>
  <mat-sidenav-content class="Main">
    <aw-topbar [nav]="mainSidenav"></aw-topbar>
    <div class="Content">
      <router-outlet></router-outlet>
    </div>

    <content-map-fab *awHasExtension="'content-map'"></content-map-fab>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-spotlight></app-spotlight>
