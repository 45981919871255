import { Routes } from '@angular/router';
import { awareAuthGuard } from '@appbolaget/aware-auth';
import { externalUnitResolver } from './external-unit.resolver';

export const routes: Routes = [
    {
        path: 'external',
        loadComponent: () => import('./external.component').then((m) => m.ExternalBaseComponent),
        children: [
            {
                path: 'invite',
                loadChildren: () => import('./invite/invite.routing').then((m) => m.routes),
            },
            {
                path: 'meeting/:uuid',
                loadComponent: () => import('../meeting/external/external-meeting.component').then((m) => m.ExternalMeetingComponent),
                canActivate: [awareAuthGuard, externalUnitResolver],
            },
        ],
    },
];
