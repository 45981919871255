import { Routes } from '@angular/router';
import { routes as pagesRoutes } from './views/pages/pages.routing';
import { routes as externalRoutes } from './modules/external/external.routing';

export const routes: Routes = [
    ...externalRoutes,
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    ...pagesRoutes,
    { path: '**', redirectTo: 'app/me' },
];
