@if (otherPosts$ | async; as otherPosts) {
  <div class="flex flex-col p-2">
    @if (otherPosts[0]?.length) {
      <h3 class="mat-headline-6 mb-1">Nyheter</h3>
      <ul class="pl-0 list-none">
        @for (post of otherPosts[0]; track post) {
          <li>
            <activity-card [post]="post"></activity-card>
          </li>
        }
      </ul>
    }
  </div>
}
@if (activities$ | async; as activities) {
  <div class="flex flex-col p-2">
    @if (activities.data?.length) {
      <h3 class="mat-headline-6 mb-1">Kommande aktiviteter</h3>
      <ul class="pl-0 list-none">
        @for (activity of activities.data; track activity) {
          <li>
            <activity-card [post]="activity"></activity-card>
          </li>
        }
      </ul>
    }
  </div>
}
@if (otherPosts$ | async; as otherPosts) {
  <div class="flex flex-col p-2">
    @if (otherPosts[1]?.length) {
      <h3 class="mat-headline-6 mb-1">Övriga aktiviteter</h3>
      <ul class="pl-0 list-none">
        @for (post of otherPosts[1]; track post) {
          <li>
            <activity-card [post]="post"></activity-card>
          </li>
        }
      </ul>
    }
  </div>
}
